// extracted by mini-css-extract-plugin
export var companyInfo = "footer-module--companyInfo--YlpnH";
export var contactInfo = "footer-module--contactInfo--5D5VH";
export var contactInfoLink = "footer-module--contactInfoLink--ZXAxB";
export var contactInfoText = "footer-module--contactInfoText--eaXU-";
export var contactList = "footer-module--contactList--tueOL";
export var contactSectionWrapper = "footer-module--contactSectionWrapper--y5zAh";
export var contactText = "footer-module--contactText--I6GdN";
export var contactTitle = "footer-module--contactTitle--S+pat";
export var contactWrapper = "footer-module--contactWrapper--xK9Hx";
export var copyright = "footer-module--copyright--3wrkO";
export var footer = "footer-module--footer--YzKK5";
export var formWrapper = "footer-module--formWrapper--I2FuR";
export var input = "footer-module--input--H49XF";
export var modal = "footer-module--modal--+kmw0";
export var modalButton = "footer-module--modalButton--A2SkD";
export var modalContent = "footer-module--modalContent--WwfYG";
export var modalDetails = "footer-module--modalDetails--nyJwk";
export var modalTitle = "footer-module--modalTitle--bVF6U";
export var navigation = "footer-module--navigation--nIVgX";
export var navigationList = "footer-module--navigationList--2VL6Y";
export var overlayModal = "footer-module--overlayModal--ESmhH";
export var policy = "footer-module--policy--7J8qc";
export var policyLink = "footer-module--policyLink--MU2I6";
export var sendButton = "footer-module--sendButton--RJbaM";
export var socialMedia = "footer-module--socialMedia--F9qwY";
export var socialMediaLink = "footer-module--socialMediaLink--WiU+F";
export var socialMediaList = "footer-module--socialMediaList--ZYh4j";
export var socialMediaTitle = "footer-module--socialMediaTitle--oaQMu";
export var termsLink = "footer-module--termsLink--RxlVn";
export var title = "footer-module--title--hmgsK";