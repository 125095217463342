/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";

export const useDidUpdate = (f: VoidFunction, dependencies: any[], callOnMount = false) => {
  const didMountRef = useRef(callOnMount);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    // eslint-disable-next-line consistent-return
    return f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
