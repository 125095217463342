import React, { useState } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import cn from "classnames";

import { isMobileScreen } from "@/utils";
import { useDidUpdate, useWindowSize } from "@/hooks";
import { Hamburger } from "@/components/ui/hamburger";
import { Button } from "@/components/ui/button";
import ArrowIcon from "@/assets/svgs/icons/arrow.svg";
import CompanyLogo from "@/assets/svgs/epc-network-logo.svg";

import * as arrowStyles from "@/styles/arrow.module.css";
import * as styles from "./header.module.css";
import * as linkStyles from "../link/link.module.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const isMobile = isMobileScreen(width);
  const menuLinks = t("menu_links", { returnObjects: true });

  const onMenuButtonClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useDidUpdate(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "unset";
      document.body.style.height = "unset";
    }
  }, [isOpen]);

  const careerBtnStyles = cn(styles.careerBtn, {
    [styles.careerBtnOpen]: isOpen,
  });

  const menuNavigationStyles = cn(styles.menuNavigation, {
    [styles.menuNavigationOpen]: isOpen,
  });

  const menuLinksStyles = cn(linkStyles.menuLinks, styles.menuLinks);

  return (
    <header className={styles.header}>
      <Link to="/" title={t("main_logo_text")} className={styles.menuLogo}>
        <CompanyLogo />
      </Link>
      <nav className={menuNavigationStyles}>
        <ul className={styles.navigationList}>
          {menuLinks.map(({ link, text }) => (
            <li key={link}>
              <Link
                to={link}
                onClick={closeMenu}
                className={menuLinksStyles}
                activeClassName={linkStyles.menuLinksActive}
              >
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className={careerBtnStyles}>
        <Button link href={t("career_button.link")} className={arrowStyles.arrow} icon={<ArrowIcon />}>
          {t("career_button.text")}
        </Button>
      </div>
      {isMobile && <Hamburger isOpen={isOpen} onMenuButtonClick={onMenuButtonClick} />}
    </header>
  );
};

export default Header;
