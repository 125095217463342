import React, { FC } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import MenuIcon from "@/assets/svgs/icons/menu.svg";
import CloseIcon from "@/assets/svgs/icons/close.svg";

import * as styles from "./hamburger.module.css";

type HamburgerProps = {
  isOpen: boolean;
  onMenuButtonClick: VoidFunction;
};

const Hamburger: FC<HamburgerProps> = ({ isOpen, onMenuButtonClick }) => {
  const { t } = useTranslation();

  return (
    <nav>
      <button
        className={styles.menuBtn}
        type="button"
        aria-expanded={isOpen ? "true" : "false"}
        aria-label={isOpen ? t("menuButton.openLabel") : t("menuButton.closeLabel")}
        onClick={onMenuButtonClick}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </button>
    </nav>
  );
};

export default Hamburger;
