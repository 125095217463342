import React, { FC } from "react";
import { Link } from "gatsby-plugin-react-i18next";
import cn from "classnames";

import * as styles from "./button.module.css";

export type ButtonVariant = "primary" | "secondary";

export type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  Partial<{
    variant: ButtonVariant;
    fullWidth: boolean;
    icon: string | React.ReactNode;
    link: boolean;
    local: boolean;
    href: string;
    iconClassName: string;
  }>;

const Button: FC<ButtonProps> = ({
  children,
  variant = "primary",
  icon,
  className = "",
  iconClassName = "",
  link = false,
  local = false,
  href,
  disabled,
  type = "button",
  ...rest
}) => {
  const defaultButtonClassName = "btn";
  const buttonClassNames = cn(
    styles[defaultButtonClassName],
    {
      [styles[variant]]: variant,
      [styles.link]: link,
    },
    className,
  );

  const iconClassNames = cn(styles.icon, iconClassName);

  if (local && link && href) {
    return (
      <Link className={buttonClassNames} to={href}>
        {children}
        {icon && <div className={iconClassNames}>{icon}</div>}
      </Link>
    );
  }

  if (link && href) {
    return (
      <a className={buttonClassNames} href={href} target="_blank" rel="noopener noreferrer">
        {children}
        {icon && <div className={iconClassNames}>{icon}</div>}
      </a>
    );
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={buttonClassNames} disabled={disabled} {...rest}>
      {children}
      {icon && <div className={iconClassNames}>{icon}</div>}
    </button>
  );
};

export default Button;
