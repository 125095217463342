import Modal from "react-modal";
import React, { useCallback, useMemo, useState } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useForm } from "@formspree/react";

import { getFullYear } from "@/utils";
import { Button } from "@/components/ui/button";
import LinkedinIcon from "@/assets/svgs/icons/linkedin.svg";
import InstagramIcon from "@/assets/svgs/icons/instagram.svg";
import DouIcon from "@/assets/svgs/icons/dou.svg";
import CompanyLogo from "@/assets/svgs/epc-network-logo.svg";

import * as styles from "./footer.module.css";
import * as linkStyles from "../link/link.module.css";

// id from Marek
const FORM_ID = "xbjwpene";

const Footer = () => {
  const { t } = useTranslation();
  const menuLinks = t("menu_links", { returnObjects: true });
  const contactList = t("contact_list", { returnObjects: true });

  const [state, handleSubmit] = useForm(FORM_ID);

  const [isOpen, setIsOpen] = useState(true);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const modal = useMemo(() => {
    if (state.succeeded) {
      return (
        <Modal
          className={styles.modal}
          overlayClassName={styles.overlayModal}
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel={t("contact_us.form.modal_content.content")}
        >
          <h2 className={styles.modalTitle}>{t("contact_us.form.modal_content.title")}</h2>
          <p className={styles.modalContent}>{t("contact_us.form.modal_content.content")}</p>
          <p className={styles.modalDetails}>{t("contact_us.form.modal_content.details_text")}</p>
          <Button className={styles.modalButton} onClick={toggleModal}>
            {t("contact_us.form.modal_content.close_text")}
          </Button>
        </Modal>
      );
    }

    return null;
  }, [isOpen, state.succeeded, t, toggleModal]);

  return (
    <footer className={styles.footer}>
      <section className={styles.contactInfo}>
        <h2 className={styles.title}>{t("contact_us.title")}</h2>
        <div className={styles.contactWrapper}>
          <section className={styles.contactSectionWrapper}>
            <div className={styles.formWrapper}>
              <p className={styles.contactText}>{t("contact_us.leave_information_text")}</p>
              <form onSubmit={handleSubmit}>
                <input
                  className={styles.input}
                  type="text"
                  name="name"
                  required
                  size={120}
                  aria-label={t("contact_us.form.input_name_label")}
                  placeholder={t("contact_us.form.input_name_label")}
                />
                <input
                  className={styles.input}
                  type="email"
                  name="email"
                  required
                  aria-label={t("contact_us.form.input_email_label")}
                  placeholder={t("contact_us.form.input_email_label")}
                />
                <Button className={styles.sendButton} type="submit" disabled={state.submitting}>
                  {t("contact_us.form.send_button")}
                </Button>
              </form>
              {modal}
            </div>
            <ul className={styles.contactList}>
              {contactList.map(({ title, information }) => {
                const titleList = information.split(":");
                const isEmailLink = titleList.length > 1;
                const titleText = isEmailLink ? titleList[1] : titleList[0];

                return (
                  <li key={title}>
                    <h3 className={styles.contactTitle}>{title}</h3>
                    {isEmailLink ? (
                      <a
                        href={information}
                        className={styles.contactInfoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {titleText}
                      </a>
                    ) : (
                      <p className={styles.contactInfoText}>{titleText}</p>
                    )}
                  </li>
                );
              })}
            </ul>
          </section>
          <section className={styles.socialMedia}>
            <h2 className={styles.socialMediaTitle}>{t("social_media.follow_text")}</h2>
            <ul className={styles.socialMediaList}>
              <li>
                <a
                  className={styles.socialMediaLink}
                  href={t("social_media.links.dou.link")}
                  aria-label={t("social_media.links.dou.text")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DouIcon />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialMediaLink}
                  href={t("social_media.links.instagram.link")}
                  aria-label={t("social_media.links.instagram.text")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialMediaLink}
                  href={t("social_media.links.linkedin.link")}
                  aria-label={t("social_media.links.linkedin.text")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedinIcon />
                </a>
              </li>
            </ul>
          </section>
        </div>
      </section>
      <section className={styles.companyInfo}>
        <div>
          <Link to="/" title={t("main_logo_text")}>
            <CompanyLogo />
          </Link>
          <p className={styles.copyright}>{t("copyright", { currentYear: getFullYear() })}</p>
        </div>
        <nav className={styles.navigation}>
          <ul className={styles.navigationList}>
            {menuLinks.map(({ link, text }) => (
              <li key={link}>
                <Link to={link} className={linkStyles.menuLinks}>
                  {text}
                </Link>
              </li>
            ))}
          </ul>
          <div className={styles.policy}>
            <Link className={styles.termsLink} to={t("policy_info.terms.link")}>
              {t("policy_info.terms.title")}
            </Link>
            <Link className={styles.policyLink} to={t("policy_info.policy.link")}>
              {t("policy_info.policy.title")}
            </Link>
          </div>
        </nav>
      </section>
    </footer>
  );
};

export default Footer;
