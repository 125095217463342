import React, { FC } from "react";

import { Header, Footer } from "@/components/ui";

import * as styles from "./layout.module.css";

type LayoutProps = {
  showFooter?: boolean;
};

const Layout: FC<LayoutProps> = ({ children, showFooter = true }) => {
  return (
    <div className={styles.layout}>
      <Header />
      <main>{children}</main>
      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;
