import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import ArrowIcon from "@/assets/svgs/icons/arrow.svg";
import { Button } from "../../button";

import * as styles from "./career-block.module.css";

const CareerBlock = () => {
  const { t } = useTranslation();

  return (
    <article className={styles.careersSection}>
      <div className={styles.careersSectionWrapper}>
        <p className={styles.careersSectionDescription}>
          <Trans
            components={{
              joinUsLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                <a
                  href={t("career_button.link")}
                  className={styles.careersSectionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          >
            {t("career_section.description")}
          </Trans>
        </p>
        <Button className={styles.careersBtn} href={t("career_button.link")} link icon={<ArrowIcon />}>
          {t("career_section.button_text")}
        </Button>
      </div>
    </article>
  );
};

export default CareerBlock;
